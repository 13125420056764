<template lang='pug'>
.info(v-if="info")
  .info__cont.cont
    h1.info__title.info__title_coin.title {{info.name}}
      span.info__title-abbr.text-tiny {{info.symbol}}
    span.info__algorithm {{ t('headline')}} {{info.algorithm_name}}
    .info__table-box
      pools-table(v-if="info.pools", :pools="info.pools" )
</template>

<script>
import '@/assets/styles/components/info.sass'
import PoolsTable from './PoolsTable'
import { useI18n } from 'vue-i18n'

export default {
  name: 'CoinInfo',
  components: { PoolsTable },
  props: {
    info: Object
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local'
    })
    return { t }
  }
}
</script>
<i18n>
{
  "ru": {
    "headline": "Алгоритм"
  },
  "en": {
    "headline": "Algorithm"
  },
  "de": {
    "headline": "Algorithmus"
  },
  "es": {
    "headline": "Algoritmo"
  },
  "pt": {
    "headline": "Algoritmo"
  },
  "fr": {
    "headline": "Algorithme"
  },
  "it": {
    "headline": "Algoritmo"
  },
  "pl": {
    "headline": "Algorytm"
  },
  "zh": {
    "headline": "算法"
  },
  "ja": {
    "headline": "アルゴリズム"
  }
}
</i18n>
