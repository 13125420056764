<template lang='pug'>
coin-info(v-if="coinInfo", :info="coinInfo")
pagination(v-if="pages > 1", :current-page="page", :pages="pages")
</template>

<script>
import CoinInfo from '../components/tables/CoinInfo'
import Pagination from '../components/Pagination'
import { getCoin } from '../assets/scripts/API'
import { addMeta } from '../assets/scripts/add-meta'
import { useI18n } from 'vue-i18n'

export default {
  name: 'CoinPage',
  components: { CoinInfo, Pagination },
  data () {
    return {
      blockName: '',
      symbol: '',
      algorithm: '',
      name: '',
      coinInfo: null,
      pages: 1,
      page: 1
    }
  },
  methods: {
    async getDataPage (slug, page = 1) {
      const data = await getCoin(slug, page)
      if (data.status === 'error') return await this.$router.push({ name: 'Error' })
      this.coinInfo = data
      this.pages = data.pages
      this.page = data.page
      this.name = data.name
      this.symbol = data.symbol
      this.algorithm = data.algorithm_name
    }
  },
  async mounted () {
    await this.getDataPage(this.$route.params.slug)
    addMeta(
      this.t('title', [this.name, this.symbol, this.algorithm]),
      this.t('description', [this.name, this.symbol, this.algorithm])
    )
  },
  async beforeRouteEnter (to, from, next) {
    await getCoin(to.params.slug)
    next()
  },
  async beforeRouteUpdate (to, from, next) {
    this.page = +to.query.page
    await this.getDataPage(to.params.slug, this.page)
    next()
  },
  watch: {
    '$i18n.locale' () {
      addMeta(
        this.t('title', [this.name, this.symbol, this.algorithm]),
        this.t('description', [this.name, this.symbol, this.algorithm])
      )
    }
  },
  setup () {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
      messages: {
        ru: {
          title: 'Пулы для майнинга {0} ({1}) {2}',
          description: 'Список основных пулов для майнинга {0} ({1}) по алгоритму {2}. Комиссии пулов и сравнение минимальных платежей, рейтинг'
        },
        en: {
          title: 'Mining pools {0} ({1}) {2}',
          description: 'List of main pools for mining {0} ({1}) using algorithm {2}. Pool fees and minimum payment comparison, rating'
        },
        es: {
          title: 'Grupos de minería {0} ({1}) {2}',
          description: 'Lista de grupos principales para extraer {0} ({1}) usando el algoritmo {2}. Tarifas de pool y comparación de pago mínimo, calificación'
        },
        pt: {
          title: 'Pools de mineração {0} ({1}) {2}',
          description: 'Lista de pools principais para mineração {0} ({1}) usando o algoritmo {2}. Taxas de pool e comparação de pagamento mínimo, classificação'
        },
        de: {
          title: 'Mining-Pools {0} ({1}) {2}',
          description: 'Liste der Hauptpools für das Mining von {0} ({1}) mit dem Algorithmus {2}. Poolgebühren und Mindestzahlungsvergleich, Bewertung'
        },
        fr: {
          title: 'Bassins de minage {0} ({1}) {2}',
          description: 'Liste des pools principaux pour l\'extraction de {0} ({1}) à l\'aide de l\'algorithme {2}. Comparaison des frais de pool et du paiement minimum, notation'
        },
        it: {
          title: 'Pool minerari {0} ({1}) {2}',
          description: 'Elenco dei pool principali per l\'estrazione mineraria {0} ({1}) utilizzando l\'algoritmo {2}. Confronto spese pool e pagamento minimo, rating'
        },
        pl: {
          title: 'Baseny górnicze {0} ({1}) {2}',
          description: 'Lista głównych pul do wydobywania {0} ({1}) przy użyciu algorytmu {2}. Opłaty za pulę i porównanie minimalnych płatności, ocena'
        },
        zh: {
          title: '礦池 {0} ({1}) {2}',
          description: '使用算法 {2} 挖掘 {0} ({1}) 的主池列表。 池費和最低付款比較、評級'
        },
        ja: {
          title: 'マイニングプール{0}（{1}）{2}',
          description: 'アルゴリズム{2}を使用して{0}（{1}）をマイニングするためのメインプールのリスト。 プール料金と最低支払いの比較、評価'
        }
      }
    })
    return { t }
  }
}
</script>
