export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Алгоритм"])}
      },
      "en": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithm"])}
      },
      "de": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithmus"])}
      },
      "es": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmo"])}
      },
      "pt": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmo"])}
      },
      "fr": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorithme"])}
      },
      "it": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algoritmo"])}
      },
      "pl": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorytm"])}
      },
      "zh": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["算法"])}
      },
      "ja": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アルゴリズム"])}
      }
    }
  })
}
